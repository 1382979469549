<template>
  <div class="all_main_wrap" v-if="orderDetail">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/LineOrder"
            >线上订单 /
          </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </span>
      </div>
    </div>
    <div class="bgDiv">
      <div class="detaillayoutbetween">
        <p class="type">订单状态:</p>
        <p class="value">{{ orderDetail.statusTxt }}</p>
        <p
          class="function"
          @click="(remarksVisible = true), (remarkText = orderDetail.remark), (orderFollowerText = orderDetail.orderFollower)"
        >
          添加备注
        </p>
      </div>
      <div class="detaillayoutbetween">
        <p class="type">备注:</p>
        <p class="value">{{ orderDetail.remark }}</p>
      </div>
      <div class="detaillayoutbetween">
        <p class="type">跟单人:</p>
        <p class="value">{{ orderDetail.orderFollower }}</p>
      </div>
    </div>
    <p class="headline">订单信息</p>
    <div class="bgDiv">
      <div class="detaillayout">
        <span v-if="orderDetail.code">订单编号：{{ orderDetail.code }} </span>
        <span v-if="orderDetail.userTypeTxt"
          >用户类型：{{ orderDetail.userTypeTxt }}</span
        >
        <span v-if="orderDetail.phone">手机号码：{{ orderDetail.phone ? $encryption('user:infomation:sensitivequery', 'phone', orderDetail.phone) : '-' }}</span>
      </div>
      <div class="detaillayout">
        <span v-if="orderDetail.createTime"
          >创建时间：{{ orderDetail.createTime }}
        </span>
        <span v-if="orderDetail.orderSource"
          >订单来源：{{ orderDetail.orderSource }}</span
        >
        <span v-if="orderDetail.name">用户姓名：{{ orderDetail.name }}</span>
      </div>
      <div class="detaillayout">
        <span v-if="orderDetail.orderTypeTxt"
          >订单类型：{{ orderDetail.orderTypeTxt }}
        </span>
        <span v-if="orderDetail.couponName"
          >优惠券：{{ orderDetail.couponName }}</span
        >
        <span v-if="orderDetail.closeReason"
          >取消订单原因：{{ orderDetail.closeReason }}</span
        >
        <span
          >退款状态：{{ orderDetail.refundStatus == 2?'部分退款':orderDetail.refundStatus == 3?'全部退款':'未退款' }}</span
        >
        <span
          >开票方式：{{ orderDetail.canInvoice == 1?'线上开票':'线下开票' }} 
          <span v-if="orderDetail.canInvoice == 1" class="func" @click="changeCan">{{ orderDetail.canInvoice == 1?'关闭线上开票':'打开线上开票' }}</span>
          <span v-if="orderDetail.canInvoice == 1" class="func" v-hasPermi="['invoice:supplement:update']" @click="supplementInvoice">补开发票</span></span
        >
      </div>
      <div class="detaillayout">
        <span
          >换课订单类型：{{ orderDetail.changeProductFlag == 2?'换课新订单':orderDetail.changeProductFlag == 1?'换课老订单':'非换课订单' }}</span
        >
        <span v-if="orderDetail.receiptAmount"
          >总付款额：{{
              orderDetail.receiptAmount
                ? orderDetail.receiptAmount.toFixed(2)
                : "0.00"
            }}元</span
        >
      </div>
    </div>
    <p
      class="headline"
      v-if="
        orderDetail.paymentTime ||
        orderDetail.payMethodTxt ||
        orderDetail.tradeId||orderDetail.billingAgency
      "
    >
      支付信息
    </p>
    <div class="bgDiv">
      <div class="detaillayout" v-if="orderDetail.paymentTime ||
        orderDetail.payMethodTxt ||
        orderDetail.tradeId">
        <!-- timeFormatter(orderDetail.paymentTime) -->
        <span v-if="orderDetail.paymentTime"
          >支付时间：{{ orderDetail.paymentTime }}</span
        >
        <span v-if="orderDetail.payMethodTxt || orderDetail.payMethod == 6"
          >支付方式：{{
            orderDetail.payMethodTxt
              ? orderDetail.payMethodTxt
              : orderDetail.payMethod == 6
              ? "赠品"
              : ""
          }}</span
        >
        <span v-if="orderDetail.tradeId"
          >支付交易号：{{ orderDetail.tradeId }}</span
        >
      </div>
      <div class="detaillayout">
        <!-- timeFormatter(orderDetail.paymentTime) -->
        <span v-if="orderDetail.billingAgency"
          >收款方：{{ 1==orderDetail.billingAgency?'元道':2==orderDetail.billingAgency?'出版社':'' }}</span
        >
        <span v-if="orderDetail.receivingSource"
          >渠道来源：{{ orderDetail.receivingSource }}</span
        >
      </div>
    </div>
    <p class="headline">商品信息</p>

    <a-table
      class="table-template"
      :row-selection="ifselect()"
      :rowKey="(item) => item.code"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: true }"
      @change="onPage"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="footer">
        <!-- <a-checkbox @change="onChange" :checked="isChecked" class="allSellect">
          全选logisticsStatus
        </a-checkbox> -->
        <div
          class="left"
          @click="submitProduct"
          v-hasPermi="['logistics:info:updatestatus']"
          v-if="
            orderDetail.showBtnShipment &&
            (orderDetail.status == 2 || orderDetail.status == 4)
          "
        >
          <a-button type="primary">发货</a-button>
          <p v-if="!selectedRowKeys.length">（请先选择商品）</p>
        </div>
        <div class="right">
          <p>
            商品总价：{{
              orderDetail.orderPrice
                ? orderDetail.orderPrice.toFixed(2)
                : "0.00"
            }}元
          </p>
          <p>
            运费：{{
              orderDetail.freightPrice
                ? orderDetail.freightPrice.toFixed(2)
                : "0.00"
            }}元
          </p>
          <p>
            共优惠：{{
              orderDetail.couponPrice
                ? orderDetail.couponPrice.toFixed(2)
                : "0.00"
            }}元
          </p>
          <p>
            实收款：<span class="price"
              >{{
                orderDetail.payPrice ? orderDetail.payPrice.toFixed(2) : "0.00"
              }}
              元</span
            >
          </p>
        </div>
      </template>
      <template slot="name" slot-scope="text, record">
        {{ record.name ? record.name : record.productName }}
      </template>
      <template slot="source" slot-scope="text">
        {{ text ? text : "-" }}
      </template>

      <template slot="status" slot-scope="text">
        {{ text ? text : "-" }}
      </template>

      <template slot="productPrice" slot-scope="text">
        {{ text.toFixed(2) }}
      </template>

      <template slot="discountPrice" slot-scope="text">
        {{ text ? text.toFixed(2) : "0.00" }}
      </template>

      <template slot="payPrice" slot-scope="text">
        {{ text.toFixed(2) }}
      </template>

      <template slot="productType" slot-scope="text">
        {{ productType[text - 1] }}
      </template>

      <template slot="changePrice" slot-scope="text">
        {{ text > 0 ? "+" + text : text }}
      </template>
      <template slot="evaluate" slot-scope="text, record">
        {{ orderDetail.orderType != 1 ? "--" : "" }}
        {{
          !record.evaluationStatus && orderDetail.orderType == 1 ? "未评论" : ""
        }}
        <div class="btn_router_link">
          <a
            v-show="record.evaluationStatus && orderDetail.orderType == 1"
            @click="showModal(record)"
            >{{
              record.evaluationStatus == 1
                ? "待审核"
                : record.evaluationStatus == 2
                ? "已审核"
                : "未评论"
            }}</a
          >
        </div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <div class="btn_router_link">
          <a
            v-if="orderDetail.status == 1 && $route.query.userType != 2"
            v-hasPermi="['logistics:pay:edit']"
            @click="
              (changePriceVisible = true),
              (orderProductId = record.id),
              (producePrice = record.payPrice),
              (originalPrice = (record.payPrice + Math.abs(record.changePrice)))
            "
            >改价</a
          >
          <template v-if="record.refundStatus">
            <router-link to @click.native="$router.push('/admin/OrderConfig/refunDetail?id='+orderDetail.orderId)"> 退款记录</router-link>
          </template>
          <template>
            <a @click="(orderProductId = record.id),(markVisible = true)"> 渠道标记</a>
          </template>
          <!-- <span>|</span>
          <router-link to @click.native="refundVisible = true">退款</router-link> -->
        </div>
      </template>
    </a-table>

    <p
      class="headline"
      v-if="
        (logisticsdata && logisticsdata.length) ||
        orderDetail.completeTime ||
        orderDetail.deliveryTime ||
        orderDetail.shipAddress
      "
    >
      物流信息
    </p>
    <div class="bgDiv">
      <div class="detaillayout" v-if="orderDetail.shipAddress">
        <p class="youtxt">收货人信息:</p>
        <p class="youtxt">{{ orderDetail.shipAddress.name }}</p>
        <p class="youtxt">{{ orderDetail.shipAddress.phone }}</p>
        <p class="youtxt">
          {{ orderDetail.shipAddress.provinceName
          }}{{ orderDetail.shipAddress.cityName
          }}{{ orderDetail.shipAddress.districtName
          }}{{ orderDetail.shipAddress.address }}
        </p>
        <p
          class="function"
          v-if="orderDetail.status == 1 || orderDetail.status == 2"
          v-hasPermi="['logistics:address:edit']"
          @click="changeAddressVisible = true"
        >
          修改收货地址
        </p>
      </div>
      <div
        class="detaillayout"
        v-if="orderDetail.completeTime || orderDetail.deliveryTime"
      >
        <span v-if="orderDetail.completeTime"
          >成交时间：{{ orderDetail.completeTime }}</span
        >
        <span v-if="orderDetail.deliveryTime"
          >发货时间：{{ orderDetail.deliveryTime }}</span
        >
      </div>
      <a-table
        v-if="logisticsdata && logisticsdata.length"
        class="table-template"
        :rowKey="(item, index) => index"
        :columns="columnslog"
        :loading="loading"
        :data-source="logisticsdata"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <p @click="viewLogisticsInformation(record)" v-hasPermi="['logistics:list:query']">
              {{ orderDetail.status != 1 && false ? "-" : "查看物流信息" }}
            </p>
            <br />
            <p v-hasPermi="['logistics:info:updatestatus']" @click="changecode(record)">修改物流单号</p>
          </div>
        </template>
      </a-table>
    </div>

    <p class="headline" v-if="invoicedata && invoicedata.length">发票信息</p>
    <a-table
      v-if="invoicedata && invoicedata.length"
      class="table-template"
      :rowKey="(item, index) => index"
      :columns="columnsinvo"
      :loading="loading"
      :data-source="invoicedata"
      :locale="{ emptyText: '暂无数据' }"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        <div style="text-align: center">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </div>
      </template>
      <template slot="isChange" slot-scope="item">
        <div style="text-align: center">
          {{ item == 1 ? "是" : "否" }}
        </div>
      </template>
      <template slot="status" slot-scope="item">
        <div style="text-align: center">
          {{
            item == 1 ? "开票中" : item == 2 ? "已开票" : "已作废"
          }}
        </div>
      </template>
    </a-table>
    <p class="headline" v-if="userInvoiceVO && userInvoiceVO.length">
      发票抬头
    </p>
    <a-table
      v-if="userInvoiceVO && userInvoiceVO.length"
      class="table-template"
      :rowKey="(item, index) => index"
      :columns="userInvoicecolumnsinvo"
      :loading="loading"
      :data-source="userInvoiceVO"
      :locale="{ emptyText: '暂无数据' }"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        <div style="text-align: center">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </div>
      </template>
      <template slot="headerType" slot-scope="item">
        <div style="text-align: center">
          {{
            item == 1
              ? "企业"
              : item == 2
              ? "机关执业单位"
              : item == 3
              ? "个人/非企业单位"
              : item == 4
              ? "其他"
              : ""
          }}
        </div>
      </template>
    </a-table>
    <!-- 备注弹框 -->
    <a-modal
      title="备注"
      :width="400"
      :closable="false"
      :visible="remarksVisible"
      :confirm-loading="confirmLoading"
      @cancel="remarksVisible = false"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button type="primary" @click="addremarks">确认</a-button>
        <a-button
          class="all_boder_btn"
          style="margin-left: 40px"
          @click="remarksVisible = false"
          >取消</a-button
        >
      </template>
      <a-textarea
        v-model="remarkText"
        placeholder="请输入备注"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
      <!-- <a-input style="margin-top: 10px;" placeholder="请输入跟单人" v-model="orderFollowerText" /> -->
      <a-select
          v-model="orderFollowerList"
          style="width: 100%; margin-top: 10px;"
          mode="multiple"
          placeholder="请选择跟单人"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            :value="item.name"
            v-for="item in userList"
            :key="'InstructorList' + item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
    </a-modal>
    <!-- 协商历史弹框 -->
    <a-modal
      title="协商历史"
      :visible="historyVisible"
      :footer="null"
      @cancel="historyhandleCancel"
    >
      <div class="history">
        <p class="historyItem">用户申请退款 2022-01-01 23:00:00</p>
        <p class="historyItem">货物状态：未收到货</p>
        <p class="historyItem">申请数量：1</p>
        <p class="historyItem">退款金额：¥120.00</p>
        <p class="historyItem">退款原因：不想要了</p>
        <p class="historyItem">退款描述：</p>
        <br /><br />
        <p class="historyItem">成功退款¥120.00 2022/01/15 09:05:00</p>
        <br /><br />
        <p class="historyItem">小夏确认收货 2022/01/18 09:00:00</p>
        <br /><br />
        <p class="historyItem">用户寄回商品 2022/01/18 09:00:00</p>
        <p class="historyItem">寄件人：王小国</p>
        <p class="historyItem">手机号：14234256154</p>
        <p class="historyItem">快递公司：邮政快递</p>
        <p class="historyItem">运单号：142345242567782</p>
        <br /><br />
        <p class="historyItem">小华同意退款 2022/01/12 23:00:00</p>
        <p class="historyItem">
          寄回信息：小夏 1234256527 北京市北京市朝阳区一盒阳光大厦A座2345
        </p>
        <br /><br />
        <p class="historyItem">用户申请退款 2022-01-01 23:00:00</p>
        <p class="historyItem">货物状态：已收到货</p>
        <p class="historyItem">申请数量：1</p>
        <p class="historyItem">退款金额：¥120.00</p>
        <p class="historyItem">退款原因：不想要了</p>
        <p class="historyItem">退款描述：</p>
      </div>
    </a-modal>
    <!-- 对公转账信息弹框 -->
    <!-- <a-modal
      title="对公转账信息"
      :width="400"
      :visible="messagevisible"
      :footer="null"
      @cancel="messagehandleCancel"
    >
      <div class="history">
        <p class="historyItem">付款人户名：东方红有限公司</p>
        <br>
        <p class="historyItem">开户行：北京市学清路支行</p>
        <br>
        <p class="historyItem">付款人账号：5326457823647836476372679</p>
      </div>
    </a-modal> -->
    <a-modal
      title="对公转账信息"
      :width="400"
      :visible="messagevisible"
      :closable="false"
      :confirm-loading="confirmLoading"
      @cancel="(messagevisible = false), (infomationObj = null)"
    >
      <template slot="footer">
        <a-button type="primary" @click="messagehandleCancel">关闭</a-button>
      </template>
      <p class="message">
        <span class="modeltitle">付款人户名：</span
        >{{ infomationObj ? infomationObj.accountName : "" }}
      </p>
      <p class="message">
        <span class="modeltitle">开户行：</span
        >{{ infomationObj ? infomationObj.bankName : "" }}
      </p>
      <p class="message">
        <span class="modeltitle">付款人账号：</span
        >{{ infomationObj ? infomationObj.accountNumber : "" }}
      </p>
      <p class="message">
        <span class="modeltitle">应付金额：</span
        ><span>{{ infomationObj ? infomationObj.price : "" }}元</span>
      </p>
      <div class="img">
        <p class="modeltitle">支付凭证：</p>
        <img :src="infomationObj ? infomationObj.img : ''" alt="" />
      </div>
    </a-modal>
    <!-- 审核/详情弹框 -->
    <a-modal
      title=""
      :visible="visible"
      :confirm-loading="confirmLoading"
      v-if="evaluationObj"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button
          @click="auditItem(1)"
          v-show="evaluationObj.state === 0"
          key="submit"
          type="primary"
          :loading="loading"
        >
          公开
        </a-button>
        <a-button v-show="evaluationObj.state === 0" key="back" @click="hide()">
          隐藏
        </a-button>
        <a-button
          v-if="evaluationObj.state === 0"
          class="all_btn_border btnBorder"
          @click="handleCancel"
          >暂不审核</a-button
        >
        <a-button @click="visible = false" v-else>关闭</a-button>
      </template>
      <div class="userNameAusor">
        <img :src="evaluationObj.userIcon" alt="" />
        <p>{{ evaluationObj.userName }}</p>
      </div>
      <div class="classMessage">
        <img :src="evaluationObj.productPhoto" alt="" />
        <div class="message">
          <p class="title">{{ evaluationObj.productName }}</p>
          <!-- <p class="time">第一期：2021/12/23-2021/12/30</p> -->
        </div>
      </div>
      <div class="messItem">
        评价：<img
          src="@/assets/image/flower.png"
          v-for="(item, index) in evaluationObj.evaluation"
          :key="index"
          alt=""
        />
      </div>
      <p class="messItem">点评：{{ evaluationObj.comment }}</p>
      <!-- <p class="messItem">审核状态：{{ evaluationObj.stateTxt }}</p> -->
      <p class="messItem" v-if="evaluationObj.reply">
        回复：{{ evaluationObj.reply }}
      </p>
      <div
        class="textArea"
        v-else-if="evaluationObj.reply || evaluationObj.state == 0"
      >
        <p>回复：</p>
        <a-textarea
          v-model="reply"
          placeholder=""
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
    </a-modal>
    <!-- 修改收货信息弹框 -->
    <a-modal
      title="修改收货信息"
      :width="400"
      :visible="changeAddressVisible"
      :confirm-loading="confirmLoading"
      @cancel="changeAddressVisible = false"
    >
      <template slot="footer">
        <a-button type="primary" @click="changeAddress">保存</a-button>
        <a-button type="primary" @click="changeAddressVisible = false"
          >取消</a-button
        >
      </template>
      <div class="formItem">
        <p class="formTitle">收货人：</p>
        <a-input placeholder="收货人" v-model="address.receiveName" />
      </div>
      <div class="formItem">
        <p class="formTitle">手机号：</p>
        <a-input placeholder="手机号" v-model="address.receivePhone" />
      </div>
      <div class="formItem" v-if="orderDetail.shipAddress">
        <p class="formTitle">原地址：</p>
        <p class="value">
          {{ orderDetail.shipAddress.provinceName
          }}{{ orderDetail.shipAddress.cityName
          }}{{ orderDetail.shipAddress.districtName
          }}{{ orderDetail.shipAddress.address }}
        </p>
      </div>
      <div class="formItem">
        <p class="formTitle">新地址：</p>
        <Province
          @regionCity="FunProvince"
          :value="address.addressValue"
        ></Province>
        <!-- <a-cascader :options="options" v-model="newAddress" placeholder="请选择收货地址" @change="addressCascader" /> -->
      </div>
      <div class="formItem">
        <p class="formTitle"></p>
        <a-input placeholder="地址详情" v-model="address.receiveAddress" />
      </div>
    </a-modal>
    <!-- 退款弹框 -->
    <a-modal
      title="退款"
      :visible="refundVisible"
      :confirm-loading="confirmLoading"
      @cancel="refundVisible = false"
      okText="退款"
      @ok="onSubmit"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="refundbec" label="退款原因" prop="refundbec">
          <a-select
            :default-value="form.refundbec"
            style="width: 200px"
            placeholder="请选择课程"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="jack"> Jack </a-select-option>
            <a-select-option value="lucy"> Lucy </a-select-option>
            <a-select-option value="disabled" disabled>
              Disabled
            </a-select-option>
            <a-select-option value="Yiminghe"> yiminghe </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="退款描述">
          <a-input placeholder="请输入退款描述" v-model="form.refundMess" />
        </a-form-model-item>
        <a-form-model-item ref="refundNum" label="退款数量" prop="refundNum">
          <a-input
            placeholder="最多2个"
            v-model="form.refundNum"
            @blur="
              () => {
                $refs.refundNum.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="refundType" label="退款方式">
          <a-select
            :default-value="form.refundType"
            style="width: 200px"
            placeholder="请选择课程"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="jack"> Jack </a-select-option>
            <a-select-option value="lucy"> Lucy </a-select-option>
            <a-select-option value="disabled" disabled>
              Disabled
            </a-select-option>
            <a-select-option value="Yiminghe"> yiminghe </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="refundPrice" label="退回金额">
          <a-input placeholder="最多可退120.00元" v-model="form.refundPrice" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 订单发货弹框 -->
    <!-- 订单发货弹框 -->
    <a-modal
      v-if="orederStatusVisible"
      :title="variable1 ? '修改单号' : '订单发货'"
      :visible="orederStatusVisible"
      :confirm-loading="confirmLoading"
      @cancel="changecodecancel"
      :okText="variable1 ? '确认修改' : '确认发货'"
      @ok="variable1 ? pushchangecode() : onSubmit()"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="快递公司" ref="company" prop="company">
          <a-select
            v-model="form.company"
            style="width: 200px"
            placeholder="请选择快递公司"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              :value="item.code"
              v-for="(item, index) in logisticsList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          ref="logisticsNumber"
          label="运单号"
          prop="logisticsNumber"
        >
          <a-input
            placeholder="请输入运单号"
            v-model="form.logisticsNumber"
            @blur="
              () => {
                $refs.logisticsNumber.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 改价弹框 -->
    <a-modal
      title="商品改价"
      :closable="false"
      :visible="changePriceVisible"
      :confirm-loading="confirmLoading"
      okText="确认"
      @cancel="changePriceVisible = false"
      @ok="changePrice"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="商品小计">
          <p>{{ producePrice.toFixed(2) }}</p>
        </a-form-model-item>
        <a-form-model-item ref="refundNum" label="新商品小计">
          <a-input-number style="width: 100%;" v-model="form.newPrice" :max="originalPrice" placeholder="请输入您要更改的金额" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 物流信息 -->
    <a-modal
      title="物流信息"
      :maskClosable="true"
      :closable="true"
      :visible="logisVisible"
      @cancel="logisVisible = false"
    >
      <template slot="footer">
        <a-button type="primary" @click="logisVisible = false"
          >我知道了</a-button
        >
      </template>
      <Logistics :data="logisticsDate" v-if="logisticsDate" />
    </a-modal>

    <!-- 标记渠道方 -->
    <a-modal
      title="标记渠道方"
      :maskClosable="true"
      :closable="true"
      :visible="markVisible"
      @cancel="markVisible = false"
    >
      <div>
        <a-select
          placeholder="请选择渠道方"
          v-model="channelCode"
          style="width:100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="item.channelCode" v-for="item in channelData" :key="item.id">{{item.channelName}}</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onBatchMark"
          >确认</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品",
    align: "center",
    width: "160px",
    fixed: "left",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "商品编码",
    align: "center",
    width: "100px",
    dataIndex: "code",
  },
  {
    title: "单价",
    align: "center",
    width: "88px",
    dataIndex: "productPrice",
    scopedSlots: { customRender: "productPrice" },
  },
  {
    title: "数量",
    align: "center",
    width: "60px",
    dataIndex: "productCount",
  },
  {
    title: "优惠金额",
    align: "center",
    width: "100px",
    dataIndex: "discountPrice",
    scopedSlots: { customRender: "discountPrice" },
  },
  {
    title: "活动来源",
    align: "center",
    width: "160px",
    dataIndex: "source",
    scopedSlots: { customRender: "source" },
  },
  {
    title: "商品类型",
    align: "center",
    width: "100px",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },
  {
    title: "改价",
    align: "center",
    width: "100px",
    dataIndex: "changePrice",
    scopedSlots: { customRender: "changePrice" },
  },
  {
    title: "第三方渠道购买来源",
    align: "center",
    // width: "100px",
    dataIndex: "channelName",
  },
  {
    title: "商品小计",
    align: "center",
    width: "100px",
    dataIndex: "payPrice",
    scopedSlots: { customRender: "payPrice" },
  },
  {
    title: "学习进度",
    align: "center",
    width: "100px",
    dataIndex: "process",
  },
  // {
  //   title: "快递公司",
  //   align: "center",
  //   width: "100px",
  //   dataIndex: "logisticsName",
  // },
  // {
  //   title: "运单号",
  //   align: "center",
  //   width: "100px",
  //   dataIndex: "logisticsNumber",
  // },
  {
    title: "发票状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "评价",
    align: "center",
    fixed: "right",
    dataIndex: "evaluationStatus",
    scopedSlots: { customRender: "evaluate" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
const columnslog = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "快递公司",
    align: "center",
    dataIndex: "logisticsName",
  },
  {
    title: "物流单号",
    align: "center",
    dataIndex: "logisticsNumber",
  },
  {
    title: "商品信息",
    align: "center",
    dataIndex: "productInfo",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsinvo = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "发票记录号",
    align: "center",
    dataIndex: "invoiceRecordId",
  },
  {
    title: "开票金额",
    align: "center",
    dataIndex: "price",
  },
  {
    title: "是否换开",
    align: "center",
    dataIndex: "isChange",
    scopedSlots: { customRender: "isChange" },
    // 是否换开：1.是，0.否
  },
  {
    title: "商品信息",
    align: "center",
    dataIndex: "productInfo",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productTypeTxt",
    // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播
  },
  {
    title: "开票状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    // 开票状态：1.开票中/换开中 2.已开票/已换开 3.已作废
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "subOrderCode",
  },
];
const userInvoicecolumnsinvo = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "抬头类型",
    align: "center",
    dataIndex: "headerType",
    scopedSlots: { customRender: "headerType" },
  },
  {
    title: "发票抬头",
    align: "center",
    dataIndex: "invoiceHeader",
  },
  {
    title: "电子邮箱",
    align: "center",
    dataIndex: "email",
  },
  {
    title: "税号",
    align: "center",
    dataIndex: "dutyParagraph",
  },
  {
    title: "开户银行",
    align: "center",
    dataIndex: "bank",
  },
  {
    title: "银行账号",
    align: "center",
    dataIndex: "bankAccount",
  },
  {
    title: "企业地址",
    align: "center",
    dataIndex: "companyAddress",
  },
  {
    title: "企业电话",
    align: "center",
    dataIndex: "companyPhone",
  },
];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log(selected, selectedRows);
  },
};

import Logistics from "@/components/xinyu-logistics.vue";
import Province from "@/components/provinceCity";
import HeaderBox from "@/components/HeaderBox.vue";
import axios from 'axios'
import config from '@/unit/config.js'
export default {
  // 可用组件的哈希表
  components: { Logistics, Province, HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      logisVisible: false,
      markVisible:false,
      logisticsDate: null,
      evaluationObj: null,
      expandedRowKeys: [],
      columnslog,
      logisticsdata: [],
      columnsinvo,
      invoicedata: [],
      userInvoicecolumnsinvo,
      userInvoiceVO: [],
      productType: [
        "课程",
        "图书",
        "教具",
        "模拟考试",
        "电子照",
        "延期",
        "补考",
        "直播",
      ],
      logisticsList: [], // 物流公司列表
      originalPrice: 0, // 商品改价原价格
      producePrice: 0, // 改价弹框实付价格
      orderProductId: "", // 订单商品id
      infomationObj: null, // 对公转账信息
      orderDetail: null, // 订单详情
      orderId: "", // 订单Id
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      newAddress: null, // 新地址
      reply: "", // 备注文本
      columns,
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      rowSelection, // 全选
      isChecked: false,
      selectedRowKeys: [], //选择的id
      visible: false,
      confirmLoading: false,
      historyVisible: false, // 协商历史弹框
      messagevisible: false, // 对公转账信息弹框
      remarksVisible: false, // 备注弹框
      remarkText: "", // 备注信息
      orderFollowerText: "", // 跟单人信息
      orderFollowerList: "", // 跟单人信息数组
      visible: false, // 审核弹框
      changeAddressVisible: false, // 修改收货地址
      refundVisible: false, // 退款弹框
      orederStatusVisible: false, // 订单发货
      changePriceVisible: false, // 商品改价
      overtStatus: false, // 是否公开状态
      address: {
        addressValue: [], // 省市区
        receiveName: "", // 收货人姓名
        receivePhone: "", // 收货人手机号
        receiveAddress: "", // 收货人详细地址
      },
      form: {
        refundbec: undefined, // 退款原因
        refundType: undefined, // 退款方式
        refundPrice: "", // 退款金额
        refundMess: "", // 退款描述
        refundNum: "", // 退款数量
        company: undefined, // 快递公司
        newPrice: "", // 新价格
        logisticsNumber: "", // 运单号
      },
      rules: {
        refundNum: [
          { required: true, message: "请输入退款数量", trigger: "blur" },
        ],
        refundbec: [
          { required: true, message: "请选择退款原因", trigger: "change" },
        ],
        logisticsNumber: [
          { required: true, message: "请输入运单号", trigger: "blur" },
        ],
        company: [
          { required: true, message: "请选择快递公司", trigger: "change" },
        ],
      },
      options: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          children: [
            {
              value: "hangzhou",
              label: "Hangzhou",
              children: [
                {
                  value: "xihu",
                  label: "West Lake",
                },
              ],
            },
          ],
        },
        {
          value: "jiangsu",
          label: "Jiangsu",
          children: [
            {
              value: "nanjing",
              label: "Nanjing",
              children: [
                {
                  value: "zhonghuamen",
                  label: "Zhong Hua Men",
                },
              ],
            },
          ],
        },
      ],
      variable1: "", //常规变量1
      userList: [], // 后台用户列表

      channelData:[], // 渠道方列表
      channelCode:undefined,
    };
  },
  // 事件处理器
  methods: {
    // 补开发票
    supplementInvoice() {
      let _this = this;
      this.$confirm({
        title: `确定补开发票吗?`,
        okText: "确认",
        onOk() {
          _this.$message.loading({ content: "正在处理...", duration: 0 });
          _this
            .$ajax({
              url: "/hxclass-management/order/invoice/supplement?orderId=" + _this.orderDetail.orderId,
              method: "post"
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
              }
            });
        },
        onCancel() {},
      });
    },

    // 更改是否线上开票状态
    changeCan() {
      let _this = this;
      this.$confirm({
        title: `确定${_this.orderDetail.canInvoice == 1?'关闭':'打开'}线上开票吗?`,
        okText: "确认",
        onOk() {
          _this.$message.loading({ content: "正在处理...", duration: 0 });
          _this
            .$ajax({
              url: "/hxclass-management/order/changeCanInvoice?orderId=" + _this.orderDetail.orderId + '&canInvoice=' + (_this.orderDetail.canInvoice == 1?0:1),
              method: "post"
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.orderDetail.canInvoice = _this.orderDetail.canInvoice == 1?0:1
                _this.$message.success("操作成功");
              }
            });
        },
        onCancel() {},
      });
    },
    // 返回列表是否选择
    ifselect() {
      let obj = {};
      if (
        this.orderDetail.showBtnShipment &&
        (this.orderDetail.status == 2 || this.orderDetail.status == 4)
      ) {
        obj = {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.select,
          onSelectAll: this.selectAll,
          columnTitle: "选择",
          fixed: true,
          getCheckboxProps: (record) => ({
            // Column configuration not to be checked,
            props: {
              disabled:
                (record.logisticsStatus == 2 &&
                  record.code &&
                  (record.productType == 2 || record.productType == 3)) ||
                record.productType == 1 ||
                record.productType == 4,
            },
          }),
        };
      } else {
        obj = null;
      }

      return obj;
    },
    // 查看物流信息
    viewLogisticsInformation(item) {
      this.logisticsDate = null;
      let parceObj = this.tableData.filter((ite) => {
        return ite.logisticsNumber == item.logisticsNumber;
      });
      if (parceObj.length) {
        this.$ajax({
          url: "/hxclass-management/order/express/particulars",
          method: "get",
          params: {
            logisticsCode: parceObj[0].logisticsCode,
            logisticsNumber: parceObj[0].logisticsNumber,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            if (!res.data) {
              return this.$message.error("暂未查到物流信息，请稍后再试");
            }
            this.logisticsDate = res.data;
            this.logisVisible = true;
          } else {
          }
        });
      }
    },
    // 关闭发货弹框
    changecodecancel() {
      this.form.company = undefined;
      this.form.logisticsNumber = "";
      this.orederStatusVisible = false;
    },
    // 修改物流单号
    changecode(e) {
      // 接受信息
      this.form.company = e.logisticsCode;
      this.form.logisticsNumber = e.logisticsNumber;
      this.form.id = e.id;
      this.variable1 = 1;
      this.orederStatusVisible = true;
      return;
    },
    // 提交修改的数据
    pushchangecode() {
      let logisticsName = "";
      this.logisticsList.map((item) => {
        if (item.code == this.form.company) {
          logisticsName = item.name;
        }
      });
      this.$ajax({
        method: "post",
        url: "/hxclass-management/order/logistics/revise",
        params: {
          logisticsCode: this.form.company,
          logisticsNumber: this.form.logisticsNumber,
          logisticsName: logisticsName, // 物流公司名称
          id: this.form.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$set(this.form, "company", undefined);
          this.$set(this.form, "logisticsNumber", "");
          this.orederStatusVisible = false;
          this.getOrderDetail();
          this.$message.success("发货成功");
        } else {
          this.$message.error("操作失败");
          this.$router.go(-1);
        }
      });
    },
    submitProduct() {
      if (this.selectedRowKeys.length) {
        let status = true;
        this.tableData.map((item) => {
          if (this.selectedRowKeys.indexOf(item.code) != -1) {
            if (item.productType == 1 || item.productType == 4) {
              status = false;
            }
          }
        });
        if (status) {
          this.orederStatusVisible = this.selectedRowKeys.length ? true : false;
        } else {
          this.$message.error("当前选中商品包含虚拟服务，请重新勾选");
        }
      } else {
        this.$message.error("请选择商品");
      }
    },
    // 获取评价详情
    showModal(e) {
      this.$ajax({
        url: "/hxclass-management/order-comment/" + e.evaluationId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.evaluationObj = res.data;
          this.visible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 审核评价
    auditItem(e) {
      this.$ajax({
        url: "/hxclass-management/order-comment/audit",
        method: "put",
        params: {
          evaluationId: this.evaluationObj.evaluationId,
          reply: this.reply,
          state: e, // 状态：1.公开 2.隐藏
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.handleCancel();
          this.getOrderDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCancel(e) {
      this.evaluationObj = null;
      this.visible = false;
    },
    // 隐藏
    hide() {
      let _this = this;
      this.$confirm({
        title: "隐藏",
        content:
          "您隐藏该用户的评价后，用的评价将不被其他人看见，您的回复与用户的评价仅其本人可见。",
        onOk() {
          _this.auditItem(2);
        },
        onCancel() {},
      });
    },
    // 日期处理
    timeFormatter(time) {
      if (!time) return time;
      var date = time.substr(0, 10); //年月日
      var hours = time.substring(11, 13);
      var minutes = time.substring(14, 16);
      var seconds = time.substring(17, 19);
      var timeFlag = date + " " + hours + ":" + minutes + ":" + seconds;
      timeFlag = timeFlag.replace(/-/g, "/");
      timeFlag = new Date(timeFlag);
      timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
      timeFlag =
        timeFlag.getFullYear() +
        "-" +
        (timeFlag.getMonth() + 1 < 10
          ? "0" + (timeFlag.getMonth() + 1)
          : timeFlag.getMonth() + 1) +
        "-" +
        (timeFlag.getDate() < 10
          ? "0" + timeFlag.getDate()
          : timeFlag.getDate()) +
        " " +
        (timeFlag.getHours() < 10
          ? "0" + timeFlag.getHours()
          : timeFlag.getHours()) +
        ":" +
        (timeFlag.getMinutes() < 10
          ? "0" + timeFlag.getMinutes()
          : timeFlag.getMinutes()) +
        ":" +
        (timeFlag.getSeconds() < 10
          ? "0" + timeFlag.getSeconds()
          : timeFlag.getSeconds());
      return timeFlag;
    },
    // 获取物流公司列表
    getLogisticsList() {
      this.$ajax({
        url: "/hxclass-management/logistics-company/",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.logisticsList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 计算改价最高价格
    jisuanPrice() {

    },
    // 改价
    changePrice() {
      if (!this.form.newPrice) {
        return this.$message.error("请先输入新价格");
      }
      if (this.form.newPrice < 0) {
        return this.$message.error("新价格不能小于零");
      }
      let _this = this;
      this.$confirm({
        title: `是否确认将价格改为 ${_this.form.newPrice} 元？`,
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/order/product-price/change",
              method: "put",
              params: {
                orderProductId: _this.orderProductId,
                productPrice: _this.form.newPrice,
                orderCode: _this.orderDetail.code,
              },
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.form.newPrice = "";
                _this.changePriceVisible = false;
                _this.getOrderDetail();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 机构整个订单改价
    mgechangePrice() {
      let _this = this;
      this.$confirm({
        title: `是否确认将价格改为 ${_this.form.newPrice} 元？`,
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/order/org/order/changePrice",
              method: "post",
              params: {
                orderId: _this.orderId,
                price: _this.form.newPrice,
              },
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.form.newPrice = "";
                _this.changePriceVisible = false;
                _this.getOrderDetail();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 对公转账审核弹框
    infomationExamine(e) {
      this.$ajax({
        url: "/hxclass-management/order/transfer/details",
        method: "get",
        params: {
          orderId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.infomationObj = res.data;
          this.messagevisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取省市区编码
    FunProvince(data) {
      this.$set(this.address, "addressValue", data);
    },
    // 修改是否公开状态
    changeovertStatus(e) {
      this.overtStatus = e.target.checked;
    },
    // 表单校验
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let arr = [];
          this.tableData.forEach((item) => {
            if (this.selectedRowKeys.indexOf(item.code) != -1) {
              if (item.logisticsStatus === null) {
                return this.$message.error(
                  "您选中的商品中包含虚拟商品，请重新选择"
                );
              } else if (item.logisticsStatus != 1) {
                return this.$message.error("您选中的商品已发货，请重新选择");
              }
              let logisticsName = "";
              this.logisticsList.map((item) => {
                if (item.code == this.form.company) {
                  logisticsName = item.name;
                }
              });
              arr.push({
                id: item.logisticsId, // 物流信息id
                logisticsCode: this.form.company, // 物流公司编码
                logisticsName: logisticsName, // 物流公司名称
                logisticsNumber: this.form.logisticsNumber, // 物流单号
              });
            }
          });
          this.$ajax({
            url: "/hxclass-management/order/batch/shipment",
            method: "post",
            params: arr,
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.selectedRowKeys = [];
              this.$set(this.form, "company", undefined);
              this.$set(this.form, "logisticsNumber", "");
              this.orederStatusVisible = false;
              this.getOrderDetail();
              this.$message.success("发货成功");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 添加备注确认按钮
    addremarks() {
      // if (!this.remarkText) {
      //   this.$message.warning("请输入备注信息");
      // } else if (!this.orderFollowerText) {
      //   this.$message.warning("请输入跟单人信息");
      // } else {
      if (!this.remarkText && this.orderFollowerList.length == 0) {
        this.remarksVisible = false;
        return
      }
        this.$ajax({
          url: "/hxclass-management/order/remarks",
          method: "put",
          params: {
            orderIds: [this.orderId],
            remark: this.remarkText,
            orderFollower: this.orderFollowerList.length == 0 ? "无":this.orderFollowerList.join("、"),
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.info("添加成功");
            this.getOrderDetail();
            this.remarksVisible = false;
            this.remarkText = "";
            this.orderFollowerText = "";
            this.orderFollowerList = [];
          } else {
            this.$message.error(res.message);
          }
        });
      // }
    },
    // 选择新地址
    addressCascader(e) {
      // console.log(e);
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    // 隐藏
    // hide() {
    //   this.$confirm({
    //     title: "隐藏",
    //     content:
    //       "您隐藏该用户的评价后，用的评价将不被其他人看见，您的回复与用户的评价仅其本人可见。",
    //     onOk() {
    //       return new Promise((resolve, reject) => {
    //         setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //       }).catch(() => // console.log("Oops errors!"));
    //     },
    //     onCancel() {},
    //   });
    // },
    // 修改地址信息
    changeAddress() {
      // console.log(this.address);
      if (!this.address.receiveName) {
        return this.$message.warning("请输入收货人姓名");
      } else if (!this.address.receivePhone) {
        return this.$message.warning("请输入手机号");
      } else if (!this.$regular.phone.reg.test(this.address.receivePhone)) {
        return this.$message.warning("请输入正确的手机号");
      } else if (!this.address.addressValue.length) {
        return this.$message.warning("请选择收货地址");
      } else if (!this.address.receiveAddress) {
        return this.$message.warning("请输入详细地址");
      } else {
        let _params = {
          orderId: this.orderId,
          receiveName: this.address.receiveName,
          receivePhone: this.address.receivePhone,
          receiveAddress: this.address.receiveAddress,
          receiveProvince: this.address.addressValue[0],
          receiveCity: this.address.addressValue[1],
          receiveDistrict: this.address.addressValue[2],
        };
        this.$ajax({
          url: "/hxclass-management/order/personal/address",
          method: "put",
          params: _params,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.info("修改成功");
            this.changeAddressVisible = false;
            this.getOrderDetail();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 关闭对公转账信息弹框
    messagehandleCancel() {
      this.messagevisible = false;
    },
    // 关闭协商历史弹框
    historyhandleCancel() {
      this.historyVisible = false;
    },
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.id;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },
    select(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    // 获取订单详情
    getOrderDetail() {
      let url = "/hxclass-management/order/detail/personal/" + this.orderId;
      if(this.$route.query.flag == 1) { // 订单补录
        url = "/hxclass-management/order/detail/replenishment/" + this.orderId
      }else if(this.$route.query.flag == 2) { // 发货详情
        url = "/hxclass-management/order/detail/deliver/" + this.orderId
      }
      this.$ajax({
        url,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 普通商品
          this.tableData = res.data.productList;
          this.orderDetail = res.data;
          if (res.data.orderFollower) {
            this.orderFollowerList = res.data.orderFollower.split("、");
          } else {
            this.orderFollowerList = [];
          }
          if (res.data.userInvoiceVO) {
            this.userInvoiceVO = [res.data.userInvoiceVO];
          }
          if (res.data.shipAddress) {
            this.address.receiveName = res.data.shipAddress.name;
            this.address.receivePhone = res.data.shipAddress.phone;
          }
          this.logisticsdata = res.data.parcelList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取发票信息
    getinvoice() {
      this.$ajax({
        url: "/hxclass-management/order/order/invoice?orderId=" + this.orderId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoicedata = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取用户信息
    getUserList() {
      this.$ajax({
        url: "/hxclass-management/user/info/manage/user/list",
        method: "get",
        params: {"pageNum": 1, "pageSize": 100},
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = res.data.records.filter((item) => {
          return item.name === "赵雪" || item.name === "卫清静" || item.name === "汪玲" || item.name === "杨柳" || item.name === "杨少云";
        });
          this.userList = arr;
          this.userList.push("无");
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 获取渠道方
    getChannelList(){
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/login/list',
        params: {
          pageNum: 1,
          pageSize: 100,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.channelData = res.data.records
        }
      })
    },

    onBatchMark(){
      if(!this.channelCode){
        return this.$message.warning("请选择渠道方");
      }
      axios.defaults.timeout = 300000
      axios.defaults.baseURL = `${window.location.origin}/${config.baseUrl}`
      var setArry = [
        {
          channelCode:this.channelCode,
          channelType:1,
          id:this.orderProductId,
        }
      ]
      axios.post('/hxclass-management/order/channel/order/product/edit', setArry).then(res => {
        if (res.code == 200 && res.success) {
          this.getOrderDetail()
          this.$message.success("标记成功");
          this.markVisible = false
        } else {
          this.$message.error(res.message);
        }
      }).catch(error => {
          this.$message.error(error);
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getLogisticsList();
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.getOrderDetail();
      this.getinvoice();
      this.getUserList();
      this.getChannelList();
    } else {
      this.$router.back();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.textArea {
  display: flex;
  margin-top: 20px;
  p {
    width: 100px;
    font-size: 12px;
    color: #000000;
    line-height: 14px;
    text-align: right;
  }
  textarea {
    width: 264px;
    font-size: 12px;
  }
}
.textArea {
  display: flex;
  margin-top: 20px;
  padding-left: 80px;
  padding-right: 80px;
  p {
    width: 50px;
  }
}
.messItem {
  padding-top: 20px;
  padding-left: 80px;
  padding-right: 80px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
}
.classMessage {
  margin-top: 24px;
  display: flex;
  img {
    width: 150px;
    height: 100px;
  }
  .message {
    padding-left: 20px;
    line-height: 25px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      margin-top: 30px;
    }
  }
}
.userNameAusor {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  p {
    margin-left: 10px;
  }
}
.formItem {
  display: flex;
  margin-bottom: 24px;
  .cascader {
    flex: 1;
    /deep/.ant-cascader-picker {
      width: 100% !important;
    }
  }
  .formTitle {
    width: 80px;
    font-size: 12px;
    color: #000000;
    line-height: 32px;
    text-align: center;
  }
  .ant-input,
  .ant-cascader-picker {
    flex: 1;
  }
  .value {
    flex: 1;
  }
}
.messItem {
  padding-top: 20px;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  span {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.ant-checkbox-wrapper {
  padding-left: 100px;
  padding-top: 10px;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
}
.classMessage {
  margin-top: 24px;
  display: flex;
  img {
    width: 150px;
    height: 100px;
  }
  .message {
    padding-left: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      margin-top: 30px;
    }
  }
}
.userNameAusor {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  p {
    margin-left: 10px;
  }
}
/deep/.ant-modal-header {
  text-align: center;
  border: 0;
}
/deep/.ant-modal-footer {
  text-align: center;
  border: 0;
}
/deep/.ant-table-footer {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      color: #333333;
      line-height: 22px;
      margin-left: 20px;
    }
    p:last-child {
      margin-left: 40px;
    }
    .price {
      color: rgba(255, 78, 0, 1);
      font-weight: bold;
    }
  }
}
/deep/.input {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
}
.message {
  margin-bottom: 24px;
  font-size: 12px;
  color: #666666;
  line-height: 14px;
  .modeltitle {
    display: inline-block;
    width: 100px;
    color: #333333;
    text-align: right;
  }
}
.img {
  display: flex;
  .modeltitle {
    width: 100px;
    text-align: right;
    font-size: 12px;
    color: #333333;
    line-height: 14px;
  }
  img {
    width: 90px;
  }
}
.header-box {
  border: 0;
}
.headline {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.bgDiv {
  background: #f6f6fc;
  border-radius: 5px;
  padding: 16px 20px;
  .detaillayoutbetween {
    // justify-content: space-between;
    .type {
      margin-right: 20px;
    }
    .type:last-child {
      margin: 0;
    }
  }
  .detaillayoutbetween {
    display: flex;
    margin-bottom: 16px;
    font-size: 12px;
    color: #333333;
    line-height: 14px;
    .message {
      margin-left: 20px;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .message:nth-child(2) {
      margin-left: 5px;
    }
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    .type {
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .value {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
      line-height: 14px;
      margin-left: 5px;
    }
    .function {
      font-size: 12px;
      color: #2b6ed4;
      line-height: 14px;
      margin-left: 32px;
      cursor: pointer;
    }
  }
  .detaillayout:last-child {
    margin-bottom: 0;
  }
  .detaillayout {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    span {
      width: 33%;
      display: block;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
      .func{
        width: 100px;
        display: inline-block;
        cursor: pointer;
        color: #2b6ed4;
        margin-left: 20px;
      }
    }
    .youtxt {
      font-size: 12px;
      color: #333333;
      line-height: 14px;
      margin-right: 10px;
    }
    .message {
      margin-left: 20px;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .message:nth-child(2) {
      margin-left: 5px;
    }
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    .type {
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .value {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
      line-height: 14px;
      margin-left: 5px;
    }
    .function {
      font-size: 12px;
      color: #2b6ed4;
      line-height: 14px;
      margin-left: 32px;
      cursor: pointer;
    }
  }
}

.btn_router_link {
  > p {
    cursor: pointer;
  }
}
</style>
